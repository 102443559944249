* {
  font-family: 'Ubuntu', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;1,400&display=swap');

@font-face {
  font-family: "Iomanoid";
  src: url("//db.onlinewebfonts.com/t/7ab00cb67fb4f2a3deb7a10e7fed4686.eot");
  src: url("//db.onlinewebfonts.com/t/7ab00cb67fb4f2a3deb7a10e7fed4686.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/7ab00cb67fb4f2a3deb7a10e7fed4686.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/7ab00cb67fb4f2a3deb7a10e7fed4686.woff") format("woff"), url("//db.onlinewebfonts.com/t/7ab00cb67fb4f2a3deb7a10e7fed4686.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/7ab00cb67fb4f2a3deb7a10e7fed4686.svg#Iomanoid") format("svg");
}

::-webkit-scrollbar {
  width: 10px;
  display: none;
}

.rmbg {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  background: linear-gradient(180deg, rgba(9, 21, 44, 1) 0%, rgba(11, 24, 48, 0.7335105917366946) 39%, rgba(3, 10, 23, 1) 100%), url('./assets/zeusLighning.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 50px;
}

@media screen and (max-width: 499px) {
    
  .placeAndWL{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;
    display: none;
  }
  
  input{
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 16px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
    width: 170px;
  }
  
  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }
  
  #wlBtn{
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 150px;
    z-index: 100000000;
  }
  
  #wlBtn:hover{
    font-size: 18px;
  }
  
  .storyH img{
    width: 80%;
    margin-bottom: 10px;
  } 
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/grid_0.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 23px;
    color: white;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 85%;
    padding-top: 10vh;

  }

  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
     padding-bottom: 50px;
    background: rgb(9, 28, 45);
    background: linear-gradient(180deg, rgba(9, 28, 45, 1) 0%, rgba(13, 26, 38, 1) 35%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    background: rgb(11, 23, 34);
    background: linear-gradient(180deg, rgba(11, 23, 34, 1) 0%, rgba(79, 35, 13, 1) 41%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Sub {
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 75px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #77f9f1;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 100%;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #50f7ec;
    color: white;
    font-size: 35px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(169, 249, 255);
    text-align: center;
    font-size: 12px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fb7;
  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;

  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    display: none;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(140, 199, 247);
    text-shadow: 0 0 10px #a4e6f1;
  }

  .connectMob {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
   }

  .connectMob div {
    margin-left: 20px;
    margin-right: 20px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connectMob div:hover {
    color: rgb(140, 199, 247);
    text-shadow: 0 0 10px #a4e6f1;
  }


  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
   }

  .connect2Mob {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    margin-left: 10px;
   }


  .icons img {
    width: 27px;
    height: 27px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 15px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 20px;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 60px;
    height: 60px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
    z-index: 10000000000000000;

  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 78%;
    margin-top: -5%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;
    z-index: 10000000000000000;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 100px;
    display: none;
  }
  
  .cloudsMob{
    width: 100%;
    margin-top: 40px;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 180%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 130%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 180%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 90%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .pillar1 {
    width: 18%;
  }

  .pillar2 {
    width: 18%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: sticky;
    bottom: 0;
    margin-top: -80%;
    display: none;
  }

  .line {
    width: 100%;
  }

  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    width: 190px;
    height: 45px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 16px;
    font-weight: 500;

  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }

  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }

  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }

  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }

  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }

  .kave-btn:hover .kave-line:after {
    top: 0;
  }

  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 8px #7f8082;
    border-style: double;
    margin-top: 50px;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 23px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
    margin-bottom: 4%;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 25px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  
  .placeAndWL{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;
  
  }
  
  input{
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 16px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
    width: 170px;
  }
  
  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }
  
  #wlBtn{
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 150px;
    z-index: 100000000;
  }
  
  #wlBtn:hover{
    font-size: 18px;
  }
  
   .wlLine{
    background-color: #000000;
    padding: 50px;
    display: none;
  }
 
  
  .storyH img{
    width: 70%;
    margin-bottom: 10px;
  } 
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/grid_0.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 25px;
    color: white;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    padding-top: 13vh;

  }

  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    background: rgb(9, 28, 45);
    background: linear-gradient(180deg, rgba(9, 28, 45, 1) 0%, rgba(13, 26, 38, 1) 35%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    background: rgb(11, 23, 34);
    background: linear-gradient(180deg, rgba(11, 23, 34, 1) 0%, rgba(79, 35, 13, 1) 41%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Sub {
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 95px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #77f9f1;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 95%;
    height: 95%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #50f7ec;
    color: white;
    font-size: 50px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(169, 249, 255);
    text-align: center;
    font-size: 14px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fb7;
  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;

  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    display: none;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(140, 199, 247);
    text-shadow: 0 0 10px #a4e6f1;
  }

  .connectMob {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
   }

  .connectMob div {
    margin-left: 20px;
    margin-right: 20px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connectMob div:hover {
    color: rgb(140, 199, 247);
    text-shadow: 0 0 10px #a4e6f1;
  }


  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
   }

  .connect2Mob {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    margin-left: 10px;
   }


  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 17px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 20px;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 60px;
    height: 60px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 10000000000000000;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 78%;
    margin-top: -5%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 10000000000000000;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    display: none;
  }
  
  .cloudsMob{
    width: 100%;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 180%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 130%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 180%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 90%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .pillar1 {
    width: 18%;
  }

  .pillar2 {
    width: 18%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: sticky;
    bottom: 0;
    margin-top: -80%;
    display: none;
  }

  .line {
    width: 100%;
  }

  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    width: 190px;
    height: 50px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 16px;
    font-weight: 500;

  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }

  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }

  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }

  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }

  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }

  .kave-btn:hover .kave-line:after {
    top: 0;
  }

  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 8px #7f8082;
    border-style: double;
    margin-top: 50px;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    
.placeAndWL{
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  z-index: 100000000;

}

input{
  background-color: rgba(0, 30, 70, 0.568);
  border: white 1px solid;
  font-size: 20px;
  padding: 15px;
  color: #00d6fc;
  z-index: 100000000;
}

input:focus {
  background-color: #b3e9ff00;
  color: #00d6fc;
}

#wlBtn{
  background-color: rgba(0, 30, 70, 0.568);
  border: 1px solid rgb(16, 192, 223);
  color: #ffffff;
  width: 200px;
  z-index: 100000000;
}

#wlBtn:hover{
  font-size: 18px;
}

 .wlLine{
  background-color: #000000;
  padding: 50px;
  display: none;
 }

  .cloudsMob{
    display: none;
  }
  
  .storyH img{
    width: 40%;
    margin-bottom: 10px;
  } 
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/grid_0.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 28px;
    color: white;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 55%;
    padding-top: 13vh;

  }

  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    background: rgb(9, 28, 45);
    background: linear-gradient(180deg, rgba(9, 28, 45, 1) 0%, rgba(13, 26, 38, 1) 35%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    background: rgb(11, 23, 34);
    background: linear-gradient(180deg, rgba(11, 23, 34, 1) 0%, rgba(79, 35, 13, 1) 41%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Sub {
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 95px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #77f9f1;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 70%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 65%;
    height: 65%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #50f7ec;
    color: white;
    font-size: 55px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(169, 249, 255);
    text-align: center;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fb7;
  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;

  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    display: none;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(140, 199, 247);
    text-shadow: 0 0 10px #a4e6f1;
  }

  .connectMob {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
   }

  .connectMob div {
    margin-left: 20px;
    margin-right: 20px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connectMob div:hover {
    color: rgb(140, 199, 247);
    text-shadow: 0 0 10px #a4e6f1;
  }


  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
   }

  .connect2Mob {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    margin-left: 10px;
   }


  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 17px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-bottom: 20px;
  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 60px;
    height: 60px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 78%;
    margin-top: -5%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 130%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 100%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 120%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 90%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .pillar1 {
    width: 18%;
  }

  .pillar2 {
    width: 18%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: sticky;
    bottom: 0;
    margin-top: -80%;
  }

  .line {
    width: 100%;
  }

  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    width: 190px;
    height: 50px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 16px;
    font-weight: 500;

  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }

  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }

  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }

  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }

  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }

  .kave-btn:hover .kave-line:after {
    top: 0;
  }

  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 8px #7f8082;
    border-style: double;
    margin-top: 50px;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  
  .placeAndWL{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    z-index: 100000000;
  
  }
  
  input{
    background-color: rgba(0, 30, 70, 0.568);
    border: white 1px solid;
    font-size: 20px;
    padding: 15px;
    color: #00d6fc;
    z-index: 100000000;
  }
  
  input:focus {
    background-color: #b3e9ff00;
    color: #00d6fc;
  }
  
  #wlBtn{
    background-color: rgba(0, 30, 70, 0.568);
    border: 1px solid rgb(16, 192, 223);
    color: #ffffff;
    width: 200px;
    z-index: 100000000;
  }
  
  #wlBtn:hover{
    font-size: 18px;
  }
  
   .wlLine{
    background-color: #000000;
    padding: 50px;
    display: none;
  }
 

  .cloudsMob{
    display: none;
  }
 
  .storyH img{
    width: 35%;
    margin-bottom: 10px;
  } 
  .connectMob{
    display: none;
  }

  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/grid_0.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 28px;
    color: white;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 45%;
    padding-top: 20vh;

  }

  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    background: rgb(9, 28, 45);
    background: linear-gradient(180deg, rgba(9, 28, 45, 1) 0%, rgba(13, 26, 38, 1) 35%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    background: rgb(11, 23, 34);
    background: linear-gradient(180deg, rgba(11, 23, 34, 1) 0%, rgba(79, 35, 13, 1) 41%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Sub {
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 95px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #77f9f1;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 70%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 55%;
    height: 55%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #50f7ec;
    color: white;
    font-size: 55px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(169, 249, 255);
    text-align: center;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fb7;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;

  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(140, 199, 247);
    text-shadow: 0 0 10px #a4e6f1;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 17px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 78%;
    margin-top: -5%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 90%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 120%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 90%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 35px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 20px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 35px;
    height: 35px;
  }

  .pillar1 {
    width: 14%;
  }

  .pillar2 {
    width: 14%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: sticky;
    bottom: 0;
    margin-top: -63%;
  }

  .line {
    width: 100%;
  }

  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    width: 190px;
    height: 50px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 16px;
    font-weight: 500;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }

  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }

  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }

  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }

  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }

  .kave-btn:hover .kave-line:after {
    top: 0;
  }

  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 8px #7f8082;
    border-style: double;
    margin-top: 50px;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
 
  
.placeAndWL{
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  z-index: 100000000;

}

input{
  background-color: rgba(0, 30, 70, 0.568);
  border: white 1px solid;
  font-size: 20px;
  padding: 15px;
  color: #00d6fc;
  z-index: 100000000;
}

input:focus {
  background-color: #b3e9ff00;
  color: #00d6fc;
}

#wlBtn{
  background-color: rgba(0, 30, 70, 0.568);
  border: 1px solid rgb(16, 192, 223);
  color: #ffffff;
  width: 200px;
  z-index: 100000000;
}

#wlBtn:hover{
  font-size: 18px;
}

 .wlLine{
  background-color: #000000;
  padding: 50px;
  display: none;
 }

 
  .cloudsMob{
    display: none;
  }
  .storyH img{
    width: 25%;
    margin-bottom: 10px;
  }
  
  .connectMob{
    display: none;
  }
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/grid_0.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 28px;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;

  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 35%;
    padding-top: 23vh;

  }

  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
    background: rgb(9, 28, 45);
    background: linear-gradient(180deg, rgba(9, 28, 45, 1) 0%, rgba(13, 26, 38, 1) 35%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
    background: rgb(11, 23, 34);
    background: linear-gradient(180deg, rgba(11, 23, 34, 1) 0%, rgba(79, 35, 13, 1) 41%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 80px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #77f9f1;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #50f7ec;
    color: white;
    font-size: 55px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(169, 249, 255);
    text-align: center;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fb7;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;

  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(140, 199, 247);
    text-shadow: 0 0 10px #a4e6f1;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 18px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 65px;
    height: 65px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 85%;
    margin-top: -5%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 120%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 70%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 100%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 70%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 25%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 30px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 18px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 30px;
    height: 30px;
  }

  .pillar1 {
    width: 10.3%;
  }

  .pillar2 {
    width: 10.3%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: sticky;
    bottom: 0;
    margin-top: -40%;
  }

  .line {
    width: 100%;
  }

  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    width: 200px;
    height: 50px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 16px;
    font-weight: 500;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }

  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }

  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }

  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }

  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }

  .kave-btn:hover .kave-line:after {
    top: 0;
  }

  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 8px #7f8082;
    border-style: double;
    margin-top: 50px;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 2px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 85px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
}

@media screen and (min-width: 1920px) {
  
  
.placeAndWL{
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  z-index: 100000000;

}

input{
  background-color: rgba(0, 30, 70, 0.568);
  border: white 1px solid;
  font-size: 25px;
  padding: 15px;
  color: #00d6fc;
  z-index: 100000000;
}

input:focus {
  background-color: #b3e9ff00;
  color: #00d6fc;
}

#wlBtn{
  background-color: rgba(0, 30, 70, 0.568);
  border: 1px solid rgb(16, 192, 223);
  color: #ffffff;
  width: 200px;
  z-index: 100000000;
  font-size: 20px;
}

#wlBtn:hover{
  font-size: 23px;
}

 .wlLine{
  background-color: #000000;
  padding: 50px;
  display: none;
}

  
  .cloudsMob{
    display: none;
  }
  .storyH img{
    width: 22%;
    margin-bottom: 10px;
  }  
 
  .connectMob{
    display: none;
  }
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/grid_0.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 55px;
    color: white;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    padding-top: 22vh;

  }

  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
    background: rgb(9, 28, 45);
    background: linear-gradient(180deg, rgba(9, 28, 45, 1) 0%, rgba(13, 26, 38, 1) 35%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
    background: rgb(11, 23, 34);
    background: linear-gradient(180deg, rgba(11, 23, 34, 1) 0%, rgba(79, 35, 13, 1) 41%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 120px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #77f9f1;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 23px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #50f7ec;
    color: white;
    font-size: 85px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(169, 249, 255);
    text-align: center;
    font-size: 23px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fb7;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;

  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(140, 199, 247);
    text-shadow: 0 0 10px #a4e6f1;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 35px;
    height: 35px;
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 23px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 95px;
    height: 95px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 83%;
    margin-top: -5%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 150%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 110%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 130%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 90%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 60%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 45px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 27px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 40px;
    height: 40px;
  }

  .pillar1 {
    width: 12.8%;
  }

  .pillar2 {
    width: 10.3%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: sticky;
    bottom: 0;
    margin-top: -52%;
  }

  .line {
    width: 100%;
  }

  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    width: 230px;
    height: 55px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 20px;
    font-weight: 500;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }

  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }

  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }

  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }

  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }

  .kave-btn:hover .kave-line:after {
    top: 0;
  }

  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 12px #7f8082;
    border-style: double;
    margin-top: 50px;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 35px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 3px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 42px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 110px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 50px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 33px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 36px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 26px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
 
  
.placeAndWL{
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  z-index: 100000000;

}

input{
  background-color: rgba(0, 30, 70, 0.568);
  border: white 1px solid;
  font-size: 35px;
  padding: 25px;
  color: #00d6fc;
  z-index: 100000000;
}

input:focus {
  background-color: #b3e9ff00;
  color: #00d6fc;
}

#wlBtn{
  background-color: rgba(0, 30, 70, 0.568);
  border: 1px solid rgb(16, 192, 223);
  color: #ffffff;
  width: 200px;
  z-index: 100000000;
  font-size: 25px;
}

#wlBtn:hover{
  font-size: 28px;
}

 .wlLine{
  background-color: #000000;
  padding: 50px;
  display: none;
 }

  .cloudsMob{
    display: none;
  }
  .storyH img{
    width: 22%;
    margin-bottom: 10px;
  } 
  .connectMob{
    display: none;
  }
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/grid_0.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }


  .in2 p {
    font-size: 80px;
    color: white;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 40%;
    padding-top: 18vh;

  }

  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
    background: rgb(9, 28, 45);
    background: linear-gradient(180deg, rgba(9, 28, 45, 1) 0%, rgba(13, 26, 38, 1) 35%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
    background: rgb(11, 23, 34);
    background: linear-gradient(180deg, rgba(11, 23, 34, 1) 0%, rgba(79, 35, 13, 1) 41%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 150px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #77f9f1;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 35px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #50f7ec;
    color: white;
    font-size: 100px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(169, 249, 255);
    text-align: center;
    font-size: 28px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fb7;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;

  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 20px;
    margin-right: 20px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(140, 199, 247);
    text-shadow: 0 0 10px #a4e6f1;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 55px;
    height: 55px;
    margin-left: 20px;
    margin-right: 20px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 33px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 130px;
    height: 130px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 83%;
    margin-top: -5%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 170%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 120%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 130%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 90%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 60%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 63px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 35px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 60px;
    height: 60px;
  }

  .pillar1 {
    width: 12.8%;
  }

  .pillar2 {
    width: 10.3%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: sticky;
    bottom: 0;
    margin-top: -52%;
  }

  .line {
    width: 100%;
  }

  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    width: 350px;
    height: 90px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 30px;
    font-weight: 500;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }

  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }

  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }

  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }

  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }

  .kave-btn:hover .kave-line:after {
    top: 0;
  }

  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 18px #7f8082;
    border-style: double;
    margin-top: 50px;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 120px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 48px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 120px;
    height: 120px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 3px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 55px;
    border-radius: 60px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 150px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 60px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 40px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 46px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 30px;
    padding-top: 5px;
  }


  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
}

@media screen and (min-width: 3840px) {
   
.placeAndWL{
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  z-index: 100000000;

}

input{
  background-color: rgba(0, 30, 70, 0.568);
  border: white 1px solid;
  font-size: 55px;
  padding: 25px;
  color: #00d6fc;
  z-index: 100000000;
}

input:focus {
  background-color: #b3e9ff00;
  color: #00d6fc;
}

#wlBtn{
  background-color: rgba(0, 30, 70, 0.568);
  border: 1px solid rgb(16, 192, 223);
  color: #ffffff;
  width: 400px;
  z-index: 100000000;
  font-size: 40px;
}

#wlBtn:hover{
  font-size: 55px;
}

 .wlLine{
  background-color: #000000;
  padding: 50px;
  display: none;
 }

  .cloudsMob{
    display: none;
  }
  .storyH img{
    width: 22%;
    margin-bottom: 10px;
  } 
  
  .connectMob{
    display: none;
  }
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: black;
  }

  .littleNav {
    display: none;
  }

  .miniBarMain {
    display: none;
  }

  .logosMob {
    display: none;
  }

  .allWrap {
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .cont {
    background: linear-gradient(180deg, rgba(9, 28, 45, 0.10325849089635852) 0%, rgba(13, 26, 38, 0.36656381302521013) 35%, rgba(9, 28, 45, 1) 100%), url('./assets/grid_0.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .in2 p {
    font-size: 28px;
    color: white;
    text-align: center;
    font-family: "Iomanoid";
    letter-spacing: 4px;
    text-shadow: 0 0 10px #ffdc64;
    color: #ffdc64;  }

  .in2 img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 35%;
    padding-top: 25vh;

  }

  .introduction {
    font-family: "Iomanoid";
  }

  .twitter img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease;
    margin-top: -3%;
    z-index: 200000000000;

  }

  .twitter {
    z-index: 200000000000;
  }

  .twitter img:hover {
    opacity: 100%;
    z-index: 200000000000;

  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
    background: rgb(9, 28, 45);
    background: linear-gradient(180deg, rgba(9, 28, 45, 1) 0%, rgba(13, 26, 38, 1) 35%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 50px;
    background: rgb(11, 23, 34);
    background: linear-gradient(180deg, rgba(11, 23, 34, 1) 0%, rgba(79, 35, 13, 1) 41%, rgba(11, 23, 34, 1) 100%);
  }

  .boxWrap2Sub {
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH {
    font-size: 220px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #77f9f1;
  }

  .storyHK {
    font-size: 80px;
    color: white;
    font-weight: 500;
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(255, 255, 0, 0.616), 0 -10px 20px #ff80008c, 0 -18px 40px rgba(255, 0, 0, 0.719);
  }

  .storyH2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: left;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .storyH2-2 {
    font-size: 50px;
    color: white;
    font-weight: 500;
    text-align: right;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ce9381;
  }

  .about {
    color: white;
    font-size: 45px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .aboutKingdom {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .kingdomsSection {
    color: white;
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  .aboutP {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutP2 {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .zeus {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .logos {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
  }

  .logos_mobile {
    width: 30%;
    height: 30%;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 0 0 22px #704531;
    display: none;
  }

  .logoF {
    text-align: center;
    font-family: "Iomanoid";
    margin-bottom: 10px;
    text-shadow: 0 0 10px #50f7ec;
    color: white;
    font-size: 170px
  }

  .copyright {
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(169, 249, 255);
    text-align: center;
    font-size: 50px;
  }

  .footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }

  .sword {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }

  .devs {
    color: rgba(255, 255, 255, 0.774);
    text-align: center;
    margin-top: 80px;
  }

  .devs img {
    width: 30px;
    height: 30px;
    opacity: 0.9;
    transition: 0.3s ease;
  }

  .devs:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .devs img:hover {
    width: 30px;
    height: 30px;
  }

  a {
    color: #d1fdfb;
    text-decoration: none;
  }

  a:hover {
    color: #99faf5;
    text-decoration: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fb7;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;

  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Barlow Condensed', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect div {
    margin-left: 30px;
    margin-right: 30px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
  }

  .connect div:hover {
    color: rgb(140, 199, 247);
    text-shadow: 0 0 10px #a4e6f1;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-left: 10px;
  }

  .icons img {
    width: 80px;
    height: 80px;
    margin-left: 30px;
    margin-right: 30px;
    transition: transform .2s;
    z-index: 10000000;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  #fontSize {
    font-size: 45px;
    z-index: 10000000;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;

  }

  /* CSS */
  .button-62 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    background-color: #bb5a3c;
    font-size: 18px;
    padding: 13px;
    width: 200px;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: 0.3s ease;
    border-radius: 7px;
    border: none;
    font-weight: 500;
  }

  .button-62:hover {
    color: rgb(219, 219, 219);
  }

  .logo2 img {
    width: 75px;
    height: 75px;
  }

  .downImg {
    width: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
    cursor: pointer;
  }


  @keyframes arrow_down_animation {

    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }

    30% {
      opacity: 1;
    }

    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;

    }

  }

  .downImgDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -25px;

  }

  .down {
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .stickyBtn2 {
    width: 180px;
    height: 180px;
    opacity: 0.5;
    transition: 0.5s ease;
    z-index: 1;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 15%;
    margin-left: 83%;
    margin-top: -5%;
    cursor: pointer;
    transition: transform .4s;
    z-index: 1;

  }

  .stickyBtn2:hover {
    transform: scale(1.1);
    opacity: 100%;
    z-index: 1;

  }

  .clouds2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .cloud {
    position: absolute;
    top: 0;

  }

  .cloud1 {
    left: 10vw;

  }

  .cloud1 img {
    width: 220%;
    margin-top: -13%;
  }

  .cloud2 img {
    width: 160%;
    margin-top: -8%;
  }

  .cloud3 img {
    width: 190%;

  }

  .cloud1-1 {
    left: 70vw;
    animation-name: move-clouds;
    animation-duration: 600s;
    animation-iteration-count: infinite;
  }

  .cloud2 {
    left: 5px;
    animation-name: move-clouds;
    animation-duration: 200s;
    animation-iteration-count: infinite;
  }

  .cloud3 {
    left: 3px;

  }

  .cloud3-3 {
    left: 10px;
    animation-name: move-clouds;
    animation-duration: 480s;
    animation-iteration-count: infinite;
  }

  .cloud4 {
    left: 10vw;
  }

  .cloud4 img {
    width: 100%;
    margin-top: -5%;
    margin-left: -5%;
  }

  .cloud3 img {
    width: 60%;
  }

  @keyframes move-clouds {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(110vw);
    }
  }

  .cloudsLine img {
    width: 100%;
    display: flex;
  }

  .Q {
    font-size: 78px;
    color: white;
    font-weight: 500;
    margin-bottom: 10px;
    text-shadow: 0 0 10px #ecc46d;
  }

  .rmCon {
    color: white;
    font-size: 45px;
  }

  .rmConAndImg {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmConMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .rmCon img {
    width: 80px;
    height: 80px;
  }

  .pillar1 {
    width: 12.8%;
  }

  .pillar2 {
    width: 10.3%;
  }

  .pillars {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    position: sticky;
    bottom: 0;
    margin-top: -52%;
  }

  .line {
    width: 100%;
  }

  .kave-btn {
    position: relative;
    border: solid 1px rgba(194, 191, 193, 0.3);
    background: none;
    color: white;
    width: 450px;
    height: 140px;
    padding: 0;
    transition: all 0.25s ease;
    outline: none;
    font-size: 40px;
    font-weight: 500;
  }

  .connect2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .kave-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(-1px);
    background: #93edfd;
    transition: all 0.25s ease;
  }

  .kave-btn:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 60px;
    height: 1px;
    transform: translateY(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-btn:hover {
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    color: #00d6fc;
    text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
  }

  .kave-btn:hover.kave-btn:before {
    left: 0;
    width: 20px;
  }

  .kave-btn:hover.kave-btn:after {
    right: 0;
    width: 20px;
  }

  .kave-btn:hover .kave-line:before {
    bottom: 0;
  }

  .kave-btn:hover .kave-line:after {
    top: 0;
  }

  .kave-line:before {
    content: "";
    position: absolute;
    bottom: 30%;
    right: 0;
    width: 1px;
    height: 20px;
    transform: translateX(1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .kave-line:after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 20px;
    transform: translateX(-1px);
    background: #00d6fc;
    transition: all 0.25s ease;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 18px #7f8082;
    border-style: double;
    margin-top: 50px;
    z-index: 10000000;

  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 180px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
  }

  .price {
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }


  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 60px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 5px;
    width: 160px;
    height: 160px;
    border: none;

    cursor: pointer;
    background-color: #6cfc1900;
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: 3px solid rgb(255, 255, 255);
    z-index: 10000000;

  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;

    cursor: pointer;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #00000000;
    z-index: 10000000;

  }


  .btnfos-0-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #0e2942;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: white;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 80px;
    border-radius: 90px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    z-index: 10000000;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: #ecaa2f;
    font-size: 190px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;

  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 60px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 40px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 46px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 30px;
    padding-top: 5px;
  }


  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
}